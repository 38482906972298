.list-header {
    justify-content: center;
    display: flex;
    text-transform: uppercase;
    border-bottom: 1px solid #9597a1;
    border-top: 1px solid #9597a1;
    position: relative;
    align-items: center;
}

.tasks-header {
    font-style: normal;
    font-weight: bold;
    font-size: 1rem;
    line-height: 20px;
    position: relative;
    top: 1px;
    text-transform: uppercase;

    color: #0095ff;
}
.title-header {
    padding-left: 8px;

    font-style: normal;
    font-weight: bold;
    font-size: 1rem;
    line-height: 20px;

    text-transform: uppercase;

    color: #7f828e;
}

.full-name {
    font-size: 1.14rem;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    color: #151a30;
}
.sex-birthday {
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    color: #9597a1;
}
.email-phone {
    font-size: 1rem;
    font-weight: 400;
    color: #151A30;
}
.review-point {
    font-size: 1.14rem;
    font-weight: 400;
    color: #9597a1;
}
.align-self-center {
    align-self: center;
    display: flex;
}
.m-r-4 {
    margin-right: 4px;
}
.position-absolute {
    position: absolute;
}
.position-relative {
    position: relative;
}
.set-width {
    min-width: calc(100vw - 80px);
}
.rec-info-code {
    font-style: normal;
    font-weight: normal;
    font-size: 1rem;
    line-height: 1.14rem;

    color: #151a30;
}
.rec-date {
    font-style: normal;
    font-weight: normal;
    font-size: 1rem;
    line-height: 1.14rem;

    color: #7f828e;
}
.pdr-16 {
    padding-right: 1.14rem;
}
.overflow-hover:hover {
    overflow: auto !important;
}
.hidden-scrollbar::-webkit-scrollbar{
    display: none;
}